import { ReactNode } from 'react'

export interface ButtonProps {
  className?: string
  children?: ReactNode
  color?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'white'
    | 'grey'
    | 'iron'
    | 'light'
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  title?: string
  type?: 'button' | 'reset' | 'submit'
  variant?: 'contained' | 'outlined' | 'inverted' | 'text'
  size?: 'base' | 'large' | 'small' | 'medium'
  'aria-label'?: string
  tabIndex?: number
}

export const Button = ({
  className = '',
  children,
  color = 'primary',
  disabled = false,
  onClick,
  title = '',
  type = 'button',
  variant = 'contained',
  size = 'base',
  'aria-label': ariaLabel,
  tabIndex
}: ButtonProps) => {
  const buildButtonVariantClasses = () => {
    return `button-${variant}`
  }

  const buildButtonColorClasses = () => {
    switch (color) {
      case 'primary':
        return 'button-primary'
      case 'secondary':
        return 'button-secondary'
      case 'tertiary':
        return 'button-tertiary'
      case 'white':
        return 'button-white'
      case 'grey':
        return 'button-grey'
      case 'iron':
        return 'button-iron'
      case 'light':
        return 'button-light'
      default:
        return ''
    }
  }

  const buildDisabledClasses = () => {
    return disabled ? 'button-disabled' : ''
  }

  const buildButtonSizeClasses = () => {
    switch (size) {
      case 'small':
        return 'button-small'
      case 'large':
        return 'button-large'
      case 'medium':
        return 'button-medium'
      default:
        return ''
    }
  }

  return (
    <button
      tabIndex={tabIndex}
      aria-label={ariaLabel}
      disabled={disabled}
      className={`button ${buildButtonVariantClasses()} ${buildButtonColorClasses()} ${buildDisabledClasses()} ${buildButtonSizeClasses()} ${className}`}
      onClick={onClick}
      title={title}
      // eslint-disable-next-line react/button-has-type
      type={type}
    >
      {children}
    </button>
  )
}
