type TextElementType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span'
  | 'div'

interface TypographyProps extends React.HTMLProps<HTMLElement> {
  as?: TextElementType
}

// Allows for replacing which intrinsic element is used to render each size/type of typography
// ie. text rendered as a Heading1 might actually be a <h3> or <p>. This decouples semantics from presentation
const createTypographyComponent = (
  defaultAs: TextElementType,
  classes: string
) =>
  function TypographyComponent({
    as = defaultAs,
    className,
    children,
    ...props
  }: TypographyProps) {
    const AsElement = as as any
    const combinedClasses = `${classes} ${className}`
    return (
      <AsElement className={combinedClasses} {...props}>
        {children}
      </AsElement>
    )
  }

export const HeadingDisplay = createTypographyComponent(
  'h1',
  'text-shark mb-6 leading-[140%] font-display text-[30px] sm:text-[40px] lg:text-[50px]'
)

export const Heading2Display = createTypographyComponent(
  'h2',
  'text-shark mb-6 font-display font-bold text-[40px] leading-[56px]'
)

export const Heading1 = createTypographyComponent(
  'h1',
  'mb-4 font-sans font-bold text-shark leading-[120%] text-[30px] md:text-[50px]'
)

export const Heading2 = createTypographyComponent(
  'h2',
  'mb-4 font-sans font-bold leading-[34px] text-[26px] md:text-[34px] xl:text-[40px] xl:leading-[44px]'
)

export const Heading3 = createTypographyComponent(
  'h3',
  'mb-2 font-sans text-2xl font-medium'
)

export const Heading4 = createTypographyComponent(
  'h4',
  'mb-2 font-sans text-[18px] lg:text-[24px] font-semibold'
)

export const Heading5 = createTypographyComponent(
  'h5',
  'mb-2 font-sans text-[16px] md:text-[18px] leading-[22px] font-semibold'
)

export const Heading6 = createTypographyComponent(
  'h6',
  'text-[16px] leading-[16px] font-semibold uppercase'
)

export const MenuSectionTitle = createTypographyComponent(
  'h6',
  'text-[14px] font-bold uppercase'
)

export const Paragraph = createTypographyComponent('p', 'mb-4 text-base')
export const ParagraphNoSpace = createTypographyComponent('p', 'text-base')

export const Intro = createTypographyComponent(
  'p',
  '!text-[16px] md:!text-[20px] font-medium w-full leading-[24px] md:leading-[30px] !mb-0'
)

export const CallOut = createTypographyComponent(
  'p',
  'bg-aquaSpring p-8 mb-8  rounded-[10px]'
)

export const ParagraphSmaller = createTypographyComponent(
  'p',
  'mb-4 text-sm leading-[1.5]'
)

export const ParagraphIntro = createTypographyComponent(
  'p',
  'mb-6 text-base md:text-xl font-medium text-shark'
)

export const ParagraphDisplay = createTypographyComponent(
  'p',
  'mb-4 text-tomThumb font-medium text-[80px] leading-[70px] font-displayAlt'
)
export const ParagraphDisplaySpan = createTypographyComponent(
  'span',
  'mb-4 text-tomThumb font-medium text-[80px] leading-[70px] font-displayAlt'
)
export const Span = createTypographyComponent('span', '')
export const SpanDisplay = createTypographyComponent('span', 'mb-4 font-bold')
export const SpanDisplayAlt = createTypographyComponent(
  'span',
  'mb-4 text-alizarin font-bold'
)

export const Subtitle = createTypographyComponent(
  'p',
  'mb-4 text-tomThumb text-[19px]'
)

export const BlockQuote = createTypographyComponent(
  'p',
  'text-tomThumb mb-6 font-display text-xl lg:text-3xl md:text-2xl'
)
