import { useRouter } from 'next/router'
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { dashboardPath, homePath } from 'src/config/paths'

import { ModalType, useModal } from '../ModalProvider'
import { useUser } from '../UserProvider'

type FocusActionState = {
  isFocus: boolean
  returnUrl: string
  setFocussedHeader: (closeCallback?: () => void, returnUrl?: string) => void
  closeFocussedHeader: () => void
}

export const FocusActionContext = createContext<FocusActionState>({
  isFocus: false,
  returnUrl: homePath,
  setFocussedHeader: () => {},
  closeFocussedHeader: () => {}
})

export const FocusActionProvider = ({ children }: PropsWithChildren) => {
  const [isFocus, setIsFocus] = useState(false)
  const { user } = useUser()
  const [closeCallback, setCloseCallback] = useState<(() => void) | null>(null)
  const [returnUrl, setReturnUrl] = useState<string>(
    user ? dashboardPath() : homePath
  )
  const { openModal } = useModal()
  const router = useRouter()

  const closeFocus = useCallback(() => {
    if (closeCallback) {
      closeCallback()
      return
    }
    openModal(ModalType.Cancel, {
      destination: returnUrl,
      onCancel: () => {
        if (typeof returnUrl === 'string') {
          router.replace(returnUrl)
        }
      }
    })
  }, [closeCallback, openModal, returnUrl, router])

  const setFocus: FocusActionState['setFocussedHeader'] = useCallback(
    (callback, returnTo) => {
      setIsFocus(true)
      const userReturnToUrl = user ? dashboardPath() : homePath
      setReturnUrl(returnTo || userReturnToUrl)
      setCloseCallback(callback ? () => callback : null)
    },
    [user]
  )

  useEffect(() => {
    if (isFocus) {
      setIsFocus(false)
      setReturnUrl(user ? dashboardPath() : homePath)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname])

  const memoisedValue = useMemo<FocusActionState>(
    () => ({
      isFocus,
      setFocussedHeader: setFocus,
      returnUrl,
      closeFocussedHeader: closeFocus
    }),
    [isFocus, setFocus, returnUrl, closeFocus]
  )

  return (
    <FocusActionContext.Provider value={memoisedValue}>
      {children}
    </FocusActionContext.Provider>
  )
}

export const useFocusActionState = () => useContext(FocusActionContext)
